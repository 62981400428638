import React, { useState } from "react";
import { ethers } from "ethers";
import { getEthersSigner } from "../utils/ethersProvider";
import {
  ModalContainer,
  ModalContent,
  ModalButtonContainer,
  LoadingSpinner,
  ProgressBarContainer,
  ProgressBar,
  ModalButton,
  ModalInput,
  ErrorText,
} from "./index";
import { abis, addresses } from "@my-app/contracts";

const JudgeActionModal = ({ config, isOpen, onClose, action, maxAmount, refreshJudgeData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [progressStep, setProgressStep] = useState(0); // 0: Not started, 1: Approving, 2: Processing
  const [error, setError] = useState(null);

  const handleApproveAndAction = async () => {
    try {
      setIsLoading(true);
      setProgressStep(1);
      setError(null);

      const signer = await getEthersSigner(config);
      const tokenContract = new ethers.Contract(addresses.beachheadToken, abis.erc20, signer);
      const judgeContract = new ethers.Contract(addresses.beachheadJudge, abis.BeachheadJudge, signer);

      const parsedAmount = ethers.utils.parseUnits(amount, 18);

      if (action === "join") {
        // Check current allowance
        const currentAllowance = await tokenContract.allowance(await signer.getAddress(), addresses.beachheadJudge);

        if (currentAllowance.lt(parsedAmount)) {
          // Approve maximum amount (unlimited) if current allowance is insufficient
          const maxUint256 = ethers.constants.MaxUint256;
          const txApprove = await tokenContract.approve(addresses.beachheadJudge, maxUint256);
          await txApprove.wait();
        }
      }

      setProgressStep(2);

      const tx = action === "join" ? await judgeContract.join(parsedAmount) : await judgeContract.exit(parsedAmount);
      await tx.wait();

      refreshJudgeData();
      onClose();
    } catch (error) {
      console.error(`Failed to ${action} as judge:`, error);

      if (error.code === ethers.errors.UNPREDICTABLE_GAS_LIMIT) {
        console.error("Custom error data:", error.error.data);
        setError(`Failed to ${action}. Please ensure the input amount is correct and try again.`);
      } else {
        setError(`Failed to ${action}. Please try again later.`);
      }
    } finally {
      setIsLoading(false);
      setProgressStep(0);
    }
  };

  return (
    isOpen ? (
      <ModalContainer onClick={onClose}>
        <ModalContent onClick={(e) => e.stopPropagation()}>
          <h2>{action === "join" ? "Add Vote Weight" : "Remove Vote Weight"}</h2>
          {isLoading ? (
            <>
              <LoadingSpinner />
              <ProgressBarContainer>
                <ProgressBar progress={progressStep === 1 ? 50 : 100} />
                <span>{progressStep === 1 ? "Step 1 of 2: Approving" : "Step 2 of 2: Processing"}</span>
              </ProgressBarContainer>
            </>
          ) : (
            <>
              <ModalInput
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder={`Amount (max: ${maxAmount})`}
                max={maxAmount}
              />
              {error && <ErrorText>{error}</ErrorText>}
              <ModalButtonContainer>
                <ModalButton onClick={handleApproveAndAction}>{action === "join" ? "Increase" : "Decrease"}</ModalButton>
                <ModalButton onClick={onClose}>Close</ModalButton>
              </ModalButtonContainer>
            </>
          )}
        </ModalContent>
      </ModalContainer>
    ) : null
  );
};

export default JudgeActionModal;
