import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BigNumber, ethers } from 'ethers';
import { rollChallenge } from '../utils/rollChallenge';
import ChallengeDisplay from './ChallengeDisplay';
import { getRandomNumberInRange } from '../lib/randomItem';
import { getEthersSigner } from "../utils/ethersProvider";
import {
  PageContainer,
  ColumnContainer,
  HomeContainer,
  Button,
  ChallengeHeader,
  MainChallenge,
  FlexLink,
  SectionHeader,
  BrowseButtonContainer,
  Image,
  SloganText,
  ExternalButton
} from './index';
import { abis, addresses } from '@my-app/contracts';
// import logo from '../assets/logo512.png';
import logo from '../assets/chaoshead.gif';

const HomePage = ({ config, walletConnected, provider }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const initialChallengeId = location.state?.challengeId || null;
  const currentChallengeIdRef = useRef(initialChallengeId); // Ref to track current challenge ID

  const beachheadContract = useMemo(() => new ethers.Contract(addresses.beachhead, abis.Beachhead, provider), [provider]);

  const [isLoading, setIsLoading] = useState(true);
  const [featureChallenge, setFeatureChallenge] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  const fetchFeatureChallenge = useCallback(async () => {
    try {
      setIsLoading(true);
      const blockNumber = await provider.getBlockNumber();
      let userAddress = ethers.constants.AddressZero;

      if (walletConnected) {
        const signer = await getEthersSigner(config);
        if (signer) {
          userAddress = await signer.getAddress();
        } else {
          console.error("Failed to get signer");
        }
      }

      const activeChallengeIds = await beachheadContract.getActiveChallenges();
      let featureChallenge;
      if (activeChallengeIds.length > 0) {
        const activeChallenges = await Promise.all(activeChallengeIds.map(async (id) => {
          return await rollChallenge(provider, id.toNumber(), userAddress);
        }));
        const validChallenges = activeChallenges.filter(challenge => challenge.stats.expireBlock > blockNumber);
        if (validChallenges.length > 0) {
          featureChallenge = validChallenges.reduce((prev, current) => (prev.stats.pot.gt(current.stats.pot) ? prev : current), { stats: { pot: BigNumber.from(0) } });

          if (featureChallenge.stats.pot.eq(0)) {
            featureChallenge = validChallenges.reduce((prev, current) => (prev.stats.total.gt(current.stats.total) ? prev : current), { stats: { total: BigNumber.from(0) } });
          }
        }
      }

      if (!featureChallenge || (featureChallenge.stats.pot.eq(0) && featureChallenge.stats.total.eq(0))) {
        featureChallenge = await rollChallenge(provider, blockNumber, userAddress);
      }

      setFeatureChallenge(featureChallenge);
    } catch (error) {
      console.error('Error fetching feature challenge:', error);
    } finally {
      setIsLoading(false);
    }
  }, [config, provider, walletConnected, beachheadContract]);

  const handleRouletteClick = useCallback(async () => {
    try {
      const blockNumber = await provider.getBlockNumber();
      const randomBlockNumber = blockNumber + getRandomNumberInRange(150, 300);
      navigate(`/helldivers2/challenge/${randomBlockNumber}`);
    } catch (error) {
      console.error('Error navigating to random challenge:', error);
    }
  }, [provider, navigate]);

  const handleTwoHourClick = useCallback(async () => {
    try {
      const blockNumber = await provider.getBlockNumber();
      const randomBlockNumber = blockNumber - getRandomNumberInRange(39600, 40000);
      navigate(`/helldivers2/browse/${randomBlockNumber}`);
    } catch (error) {
      console.error('Error navigating to two hour challenge:', error);
    }
  }, [provider, navigate]);

  useEffect(() => {
    fetchFeatureChallenge();
  }, [fetchFeatureChallenge]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (isLoading || !featureChallenge) {
    return (
      <PageContainer>
        <ChallengeHeader>Loading...</ChallengeHeader>
      </PageContainer>
    );
  }

  const uniswapUrl = `https://app.uniswap.org/swap?theme=dark&inputCurrency=ETH&outputCurrency=${addresses.beachheadToken}&chain=base`;
  const uniswapUsdcUrl = `https://app.uniswap.org/swap?theme=dark&inputCurrency=ETH&outputCurrency=${addresses.usdc}&chain=base`;

  const Content = (
    <ColumnContainer>
      <Image src={logo} alt="Logo" />
      <SloganText>Vera Libertas!</SloganText>
      <SectionHeader>Browse Chaos Orders</SectionHeader>
      <BrowseButtonContainer>
        <Button onClick={() => navigate('/helldivers2/browse')}>All Chaos Orders</Button>
        <Button onClick={() => navigate('/helldivers2/list?set=active')}>Active Orders</Button>
        <Button onClick={() => navigate('/helldivers2/list?set=mine')}>My Orders</Button>
        <Button onClick={handleTwoHourClick}>Two Hour Challenge</Button>
        <Button onClick={handleRouletteClick}>Chaos Roulette</Button>
      </BrowseButtonContainer>
      <SectionHeader>Navigation</SectionHeader>
      <BrowseButtonContainer>
        <Button onClick={() => navigate('/helldivers2/judge')}>Judge</Button>
        <ExternalButton href={uniswapUsdcUrl} target="_blank" rel="noopener noreferrer">
          Buy/Sell USDC
        </ExternalButton>
        <ExternalButton href={uniswapUrl} target="_blank" rel="noopener noreferrer">
          Buy/Sell BCHD
        </ExternalButton>
        <Button onClick={() => navigate('/about')}>About</Button>
        <Button onClick={() => navigate('/helldivers2/preferences')}>Preferences</Button>
        <ExternalButton href="https://discord.gg/P7hD7g2rM9" target="_blank" rel="noopener noreferrer">
          Community
        </ExternalButton>
        <ExternalButton href="https://www.youtube.com/@TheBeachhead" target="_blank" rel="noopener noreferrer">
          YouTube
        </ExternalButton>
      </BrowseButtonContainer>
      <SectionHeader>Priority Chaos Order</SectionHeader>
      <FlexLink to={`/helldivers2/challenge/${featureChallenge.id}`} state={{ challengeId: currentChallengeIdRef.current }}>
        <MainChallenge>
          <ChallengeDisplay challenge={featureChallenge} displayAccept={false} />
        </MainChallenge>
      </FlexLink>
    </ColumnContainer>
  );

  return (
    <PageContainer>
      {isMobile ? Content : <HomeContainer>{Content}</HomeContainer>}
    </PageContainer>
  );
};

export default HomePage;
