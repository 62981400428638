import styled from "styled-components";
import { Link } from 'react-router-dom';

// Create a container that will hold the challenge content and apply animations
export const SwipeContainer = styled.div`
  width: 100%;
  transition: transform 0.3s ease-in-out;

  &.swipe-left {
    transform: translateX(-100%);
  }

  &.swipe-right {
    transform: translateX(100%);
  }
`;

export const StyledLink = styled(Link)`
  color: #61dafb;
  &:visited {
    color: inherit;
  }
`;

export const YellowText = styled.p`
  color: yellow;
`;

export const Image = styled.img`
  width: 80%;
  height: auto;
  margin-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export const LogoImage = styled.img`
  width: 50%;
  height: auto;
  margin-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export const TitleImage = styled.img`
  margin-bottom: 20px;

  @media (max-width: 768px) {
    position: absolute;
    top: 10px;
    left: 100px;
  }
`;

export const Body = styled.div`
  align-items: center;
  color: white;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  padding-top: 80px; /* Adjust to provide space for fixed header */
`;

export const Button = styled.button`
  background-color: white;
  border: none;
  border-radius: 8px;
  color: #282c34;
  cursor: pointer;
  font-size: 16px;
  margin: 0px 20px;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;

  &:disabled {
    background-color: #bbb;
    cursor: not-allowed;
  }
`;

export const ExternalButton = styled(Button).attrs({
  as: 'a'
})`
  text-align: center;
`;

export const FixedHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #282c34;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  z-index: 1000;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    justify-content: center;
    text-align: center;
    order: 1;
    width: 100%;
  }
`;

export const Title = styled.h1`
  margin: 0;
  text-align: center;
  font-size: 2rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const WalletContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 50px;
  top: 10px;

  @media (max-width: 768px) {
    position: absolute;
    margin-top: 10px;
    order: 2;
  }
`;

export const ChallengeHeader = styled.header`
  align-items: center;
  text-align: center;
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: #2C3039;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  min-height: 600px;
`;

export const AcceptButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #bbb;
    cursor: not-allowed;
  }
`;

export const StyledSpan = styled.span`
  color: ${props => props.color};
  font-size: 18px;
`;

export const ItemText = styled.p`
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  overflow-wrap: break-word;
`;

export const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  background-color: #282c34;
  width: 90vw;
`;

export const MainChallenge = styled(Container)`
  flex: 3;
  margin: 10px;
  flex-basis: auto;
  width: 95%;
  border: 2px solid ${props => props.borderColor || 'transparent'};
`;

export const PreviousChallenge = styled(Container)`
  flex: 2;
  margin: 10px;
  flex-basis: 33.33%;
  transform: scale(0.8);
  opacity: 0.25;
  cursor: pointer;
`;

export const NextChallenge = styled(Container)`
  flex: 2;
  margin: 10px;
  flex-basis: 33.33%;
  transform: scale(0.8);
  opacity: 0.25;
  cursor: pointer;
`;

export const NavigationButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  padding: 10px;
  &:hover {
    color: #61dafb;
  }
  flex: 0 0 auto;
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  background: #2C3039;
  color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalInput = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #333;
  color: white;
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
`;

export const VoteWeightText = styled.p`
  color: ${props => props.color};
  font-size: 18px;
`;

export const ModalButton = styled(Button)`
  width: 96%;
`;

export const LoadingSpinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 0.8s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export const ModalDetailText = styled.p`
  width: 100%;
  text-align: left;
  margin: 5px 0;
  font-size: 16px;
  color: white;
  margin-bottom: 10px;
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #555;
  border-radius: 5px;
  margin: 10px 0;
  overflow: hidden;
`;

export const ProgressBar = styled.div`
  width: ${props => props.progress}%;
  height: 35px;
  background-color: #4caf50;
  transition: width 0.2s ease-in-out;
  position: center;

  span {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-weight: bold;
    font-size: 16px;
    margin: 5px 0;
  }
`;

export const ProgressBarRed = styled.div`
  width: ${props => props.progress}%;
  height: 35px;
  background-color: #cc4b4b;
  transition: width 0.2s ease-in-out;
  position: center;

  span {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-weight: bold;
    font-size: 16px;
    margin: 5px 0;
  }
`;

export const FlexLink = styled(Link)`
  display: flex;
  flex: 2;
  margin: 10px;
  flex-basis: 33.33%;
  text-decoration: none;
  color: inherit;
  position: relative;
`;

export const NewMissionsLink = styled.a`
  cursor: pointer;
  background-color: #2C3039;
  color: #61dafb;
  border-radius: 8px;
  padding: 8px 16px;
  text-decoration: none;
  font-size: 1rem;

  &:hover {
    color: #21a1f1;
  }
`;

export const ReturnToCurrentLink = styled.button`
  background: none;
  background-color: #2C3039;
  border: none;
  color: #61dafb;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 10px;
  text-decoration: underline;
  &:hover {
    color: #21a1f1;
  }
`;

export const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const CenteredTextContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MobileMissionsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const MissionsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 33%;
`;

export const HomeContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 33%;
`;

export const AboutContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 33%;
  margin: 10px 0;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const JudgeContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 33%;
  margin: 10px 0;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
`;

export const FailedButton = styled.button`
  flex: 1;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #cc4b4b; /* Muted red */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 5px; /* Added margin for spacing between buttons */
  transition: background-color 0.3s;

  &:hover {
    background-color: #a94444; /* Darker muted red */
  }

  &:disabled {
    background-color: #bbb;
    cursor: not-allowed;
  }
`;

export const PassedButton = styled.button`
  flex: 1;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #4bbf73; /* Muted green */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 5px; /* Added margin for spacing between buttons */
  transition: background-color 0.3s;

  &:hover {
    background-color: #3da164; /* Darker muted green */
  }

  &:disabled {
    background-color: #bbb;
    cursor: not-allowed;
  }
`;

export const WithdrawButton = styled.button`
  flex: 1;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #4a90e2; /* Blue */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 5px; /* Added margin for spacing between buttons */
  transition: background-color 0.3s;

  &:hover {
    background-color: #357ab8; /* Darker blue */
  }

  &:disabled {
    background-color: #bbb;
    cursor: not-allowed;
  }
`;

export const ErrorText = styled.p`
  color: red;
  margin-top: 10px;
  font-size: 14px;
`;

export const MessageTextRed = styled.p`
  flex: 1;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #cc4b4b; /* Muted red */
  border-radius: 5px;
  text-align: center;
  margin: 10px 5px; /* Added margin for spacing */
`;

export const MessageTextGreen = styled.p`
  flex: 1;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #4b9b4b; /* Muted green */
  border-radius: 5px;
  text-align: center;
  margin: 10px 5px; /* Added margin for spacing */
`;

export const MessageTextYellow = styled.p`
  flex: 1;
  padding: 10px 20px;
  font-size: 16px;
  color: black;
  background-color: #d1b54b; /* Muted yellow */
  border-radius: 5px;
  text-align: center;
  margin: 10px 5px; /* Added margin for spacing */
`;

export const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: #2C3039;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  margin-top: 20px;
`;

export const UserTitle = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #282c34;
  width: 100%;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
`;

export const UserVideoContainer = styled.div`
  width: 100%;
  max-width: 560px; // Standard YouTube video width
  margin: 0 auto 20px;

  iframe {
    width: 100%;
    height: 315px; // Standard YouTube video height
  }
`;

export const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const UserHeader = styled.header`
  align-items: center;
  text-align: center;
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ParticipantHeader = styled.header`
  align-items: center;
  text-align: center;
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const StatusText = styled.p`
  color: ${props => props.color};
  font-size: 1rem;
  font-weight: bold;
  margin: 10px 0;
`;

export const JudgeModalButton = styled.button`
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin: 10px 0;
  transition: background-color 0.3s;
  width: 100%;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #bbb;
    cursor: not-allowed;
  }
`;

export const PassButton = styled.button`
  flex: 1;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #4bbf73; /* Muted green */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 5px; /* Reduced margin for closer spacing */
  transition: background-color 0.3s;

  &:hover {
    background-color: #3da164; /* Darker muted green */
  }

  &:disabled {
    background-color: #bbb;
    cursor: not-allowed;
  }
`;

export const FailButton = styled.button`
  flex: 1;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #cc4b4b; /* Muted red */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 5px; /* Reduced margin for closer spacing */
  transition: background-color 0.3s;

  &:hover {
    background-color: #a94444; /* Darker muted red */
  }

  &:disabled {
    background-color: #bbb;
    cursor: not-allowed;
  }
`;

export const JudgeVoteWeight = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  margin: 10px 0;
  color: white;
  text-align: center;
`;

export const SectionHeader = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 10px;
  text-align: center;
  color: #ffffff;
`;

export const BrowseButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
  width: 100%;
`;

export const MenuIcon = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
  width: 30px;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1000;

  span {
    background: #fff;
    border-radius: 2px;
    height: 3px;
    width: 100%;
    transition: all 0.3s ease;
  }
`;

export const MenuItems = styled.div`
  position: absolute;
  top: 60px;
  left: 20px;
  background: #20232a;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 999;

  a {
    color: white;
    text-decoration: none;
    padding: 10px;
    border-radius: 4px;
    transition: background 0.3s ease;

    &:hover {
      background: #61dafb;
    }
  }
`;

export const LoadingText = styled.p`
  text-align: center;
  font-size: 1.2rem;
  color: #666;
`;

export const StratagemIcon = styled.img`
  width: 21%;
  height: auto;
  margin: 0 5px;
`;

export const StratagemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const WeaponContainer = styled.div`
  border: 1px solid #fc9403;
  padding: 10px;
  margin: 10px;
  text-align: center;
  display: inline-block;
  width: 100%;
  height: 200px; /* Set a fixed height */
  position: relative;
`;

export const WeaponTypeLabel = styled.div`
  color: #fc9403;
  font-size: 14px;
  top: 10px;
  width: 100%;
`;

export const WeaponNameLabel = styled.div`
  color: white;
  font-size: 12px;
`;

export const WeaponIcon = styled.img`
  max-width: 100%;
  max-height: 60%;
  margin-top: 15px;
  margin-bottom: 15px;
  object-fit: contain; /* Ensure the image scales properly */
`;

export const SloganText = styled.p`
  font-size: 1rem;
  text-align: center;
  color: #ffffff;
  margin-top: -10px; /* Adjust spacing as needed */
  margin-bottom: 20px;
`;

export const CenteredChallengesContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 40%;
  margin: 10px 0;
  gap: 20px;
`;

export const CenteredChallengeLink = styled(Link)`
  display: flex;
  justify-content: center;
  text-decoration: none;
  color: inherit;
  margin: 10px;
`;

export const TextBlock = styled.p`
  font-size: 1rem;
  text-align: justify;
  color: #ffffff;
  margin-bottom: 20px;
  width: 100%;

  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 0.55rem;
  }
`;


export const TableRow = styled.tr`
border-bottom: 1px solid #ddd;
`;

export const TableCell = styled.td`
  padding: 8px;
  text-align: left;
  color: #ffffff;

  &:first-child {
    font-weight: bold;
  }
`;

export const TableLink = styled.a`
  color: #61dafb;
  text-decoration: none;

  &:hover {
    color: #21a1f1;
    text-decoration: underline;
  }
`;

export const SectionKey = styled.div`
  display: block;
  justify-content: center;
  margin-bottom: 20px;
`;

export const KeyContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const KeyLine = styled.div`
  width: 20px;
  height: 5px;
  background-color: ${props => props.color || 'green'};
`;

export const KeyText = styled.p`
  margin: 0;
  color: #fff;
  font-size: 16px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Input = styled.input`
  padding: 5px;
  font-size: 16px;
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  width: 16px;
  height: 16px;
`;
